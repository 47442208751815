import React, { useState } from "react"
import emailjs from "@emailjs/browser"

const NewsLetter = () => {
  const [data, setData] = useState({
    email: "",
  })

  const [sucess, setSucess] = useState(false)
  const [emailError, setEmailError] = useState("")
  const [showSpinner, setShowSpinner] = useState(false)

  const inputEvent = event => {
    const { value, name } = event.target
    setData(preValue => {
      return { ...preValue, [name]: value }
    })
    if (name === "email" && emailError) {
      setEmailError("")
    }
  }

  const submitHandler = (event: any) => {
    event.preventDefault()

    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (data.email) {
      if (!data.email.match(mailformat)) {
        setEmailError("Invalid email address")
      }
    } else if (!data.email) {
      setEmailError("Required")
    }

    if (!data.email || !data.email.match(mailformat)) {
      return
    }
    setShowSpinner(true)
    const templateParams = {
      sender_email: data.email,
    }
    // console.log("templateParams", templateParams)

    emailjs
      .send(
        "service_gpwjd2j",
        "template_vxxi4mj",
        templateParams,
        "bRLofN4y5SlAu29R7"
      )
      .then(
        function (response) {
          if (response.status === 200) {
            setShowSpinner(false)
            setSucess(true)
            setTimeout(() => {
              setSucess(false)
            }, 8000)
            setData({
              email: "",
            })
          }
        },
        function (error) {
          setShowSpinner(false)
          // console.log("FAILED...", error)
        }
      )
  }
  // console.log("input", data)

  return (
    <div className="newsletter-container">
      <form onSubmit={submitHandler} className="newsletter">
        <div className="newsletter-info">
          <div>
            <div className="newsletter-text">Subscribe To Our Newsletter</div>
            <input
              className="newsletter-input"
              placeholder="Enter your email"
              value={data.email}
              name="email"
              onChange={inputEvent}
            />
          </div>
          <div>
            {sucess && (
              <span className="text-success-message">
                Thank you for subscribing our newsletter
              </span>
            )}
          </div>
          <div>
            {emailError && (
              <span className="text-danger-message">{emailError}</span>
            )}
          </div>
        </div>

        <button className="btn btn-primary">
          Subscribe{" "}
          {showSpinner && (
            <div
              style={{ width: 20, height: 20, right: 10 }}
              className="spinner-border position-absolute"
              role="status"
            >
              <span className="visually-hidden ">Loading...</span>
            </div>
          )}
        </button>
      </form>
    </div>
  )
}

export default NewsLetter
